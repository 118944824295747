import React from "react";
import GatsbyImages from "../../GatsbyImages";
import { MoneyRecharge } from "../../MoneyRecharge";

const Header = ({ data, lang }) => {
  const buttonRenderCondition =
    !!data.link.length && typeof data.link === "string";
  const linkButton = buttonRenderCondition && (
    <a href={data.link} id={data.clickID}>
      <span>{data.textlink}</span>
    </a>
  );

  return (
    <div className="banner bannerrecharge">
      <div className="container">
        <MoneyRecharge lang={lang} />
      </div>
    </div>
  );
};

export { Header };
